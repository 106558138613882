import { ButtonIcon } from "Components/Shared";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faTrash } from "@fortawesome/free-solid-svg-icons";
import { AssetType } from "Components/Shared/AssetLandingPage/types";
import { EMPTY_LABEL } from "App/constants";
import { ASSET_TYPE_ENUM } from "Components/Shared/UploadAsset/types";
import TextWrapper from "Components/Shared/TextWrapper";
import { formatDate, formatTimestamp } from "Utils/helper";

export const getAssetColumns = (onDeleteRowClicked: Function) => [
  {
    dataField: "video",
    title: "Asset",
    colWidth: "20%",
    formatter: (data: AssetType) => {
      const { thumbnail_url, of_type } = data;

      return (
        <div className="thumbnail-box position-relative">
          <img
            src={thumbnail_url}
            alt={`video thumbnail ${data.title}`}
            height="60px"
          />
          {of_type === ASSET_TYPE_ENUM.video && (
            <FontAwesomeIcon
              icon={faPlay}
              color="white"
              className="position-absolute fs-5 icon-media"
            />
          )}
        </div>
      );
    },
  },
  {
    dataField: "title",
    title: "Name",
    colWidth: "30%",
  },
  {
    dataField: "description",
    title: "Description",
    colWidth: "30%",
    formatter: (data: AssetType) => {
      return data.description ? data.description : EMPTY_LABEL;
    },
  },
  {
    dataField: "created_at",
    title: "Created at",
    colWidth: "10%",
    formatter: (data: AssetType) => {
      return data.created_at ? (
        <TextWrapper width={120} tooltipText={formatDate(data.created_at)}>
          {formatTimestamp(data.created_at)}
        </TextWrapper>
      ) : (
        EMPTY_LABEL
      );
    },
  },
  {
    dataField: "delete",
    title: "Action",
    colWidth: "10%",
    formatter: (data: AssetType) => {
      return (
        <div className="d-flex">
          <ButtonIcon
            onClick={(event) => {
              event.stopPropagation();
              onDeleteRowClicked(data.id);
            }}
          >
            <FontAwesomeIcon icon={faTrash} />
          </ButtonIcon>
        </div>
      );
    },
  },
];
