import { faTrashCan, faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ButtonIcon } from "Components/Shared";
import {
  PlayerColumnInterface,
  PlayerFormConstant,
  PlayerObjectType,
} from "Interfaces/Pages/PlayersInterface";
import { isAllowedTo } from "Utils/helper";

export const getPlayerColumns = ({
  onEditPlayers,
  onDeletePlayer,
  permissions,
}: PlayerColumnInterface) => [
  {
    dataField: "name",
    title: "Player Name",
  },
  {
    title: "Contact No.",
    formatter: (props: PlayerObjectType) =>
      props.country_code && props.contact_number
        ? `${props.country_code} ${props.contact_number}`
        : "-",
  },
  {
    dataField: "email",
    title: "Email ID",
  },
  {
    dataField: "sports_count",
    title: "No. Of Sports",
  },
  {
    dataField: "actions",
    title: "Actions",
    formatter: (props: PlayerObjectType) => (
      <div className="d-flex" key={props.id}>
        {isAllowedTo("Player", "update", permissions) && (
          <ButtonIcon
            onClick={(event) => {
              event.stopPropagation();
              onEditPlayers(props.id);
            }}
          >
            <FontAwesomeIcon icon={faPencil} />
          </ButtonIcon>
        )}
        {isAllowedTo("Player", "deactivate", permissions) && (
          <ButtonIcon
            onClick={(event) => {
              event.stopPropagation();
              onDeletePlayer(props.id);
            }}
          >
            <FontAwesomeIcon icon={faTrashCan} />
          </ButtonIcon>
        )}
      </div>
    ),
  },
];

export const USER_ATTRIBUTES: string[] = [
  "email",
  "country_code",
  "contact_number",
];

export const FORM_CONSTANT: PlayerFormConstant = {
  RouteEditPlayer: (id: number) => {
    return `/players/${id}/edit`;
  },
  RouteShowPlayer: (id: number) => {
    return `/players/${id}`;
  },
  RouteCreatePlayer: "/players/new",
};
