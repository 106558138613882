import {
  ButtonTextInterafce,
  ColumnPropsType,
  CommonListApiResponseInterface,
  ConfirmationMessageInterface,
  FilteredListInterface,
  GetColumnsInterface,
  GetListURLParamProps,
  GetListURLProps,
  HeaderTitleInterface,
  OnAddNavigateRouteInterface,
  OnEditNavigateRoute,
  OnRowClickInterface,
} from "Interfaces/Components/Shared/DisplayListComponentInterface";
import {
  ACADEMY_BASE_URL,
  BATCH,
  COACH,
  MATCH_PLAYED,
  PLAYER,
  USER_LIST,
} from "App/constants";
import { getBatchColumns } from "Pages/Batches/constants";
import { getPlayerColumns } from "Pages/Players/constants";
import { getCoachColumns } from "Pages/Coaches/constants";
import { getMatchPlayedColumns } from "Components/Shared/UserDetailsComponent/constants";
import { getAcademyId } from "Utils/utils";

export const FORM_CONSTANT = {
  Coaches: "Coaches",
  Player: "Player",
  CreateCoach: "Create New Coach",
  Search: "Search",
  Confirm: "Confirm",
  Ok: "Ok",
  TextCenter: "text-center deactivate-close-btn",
  TextEnd: "text-end",
  RouteCreatePlayer: "/players/new",
  RouteAddCoach: "/coaches/new",
  ButtonsProps: [
    {
      title: "Confirm",
      onClick: false,
    },
    {
      title: "Ok",
      onClick: false,
    },
  ],
  RouteEditPlayer: (id: number) => {
    return `/players/${id}/edit`;
  },
  RouteShowPlayer: (id: number) => {
    return `/players/${id}`;
  },
  RouteEditCoach: (id: number) => {
    return `/coaches/${id}/edit`;
  },
  RouteShowCoach: (id: number) => {
    return `/coaches/${id}`;
  },
};

export const CONFIRMATION_MESSAGE: ConfirmationMessageInterface = {
  [PLAYER]: "Do you want to de-activate this Player",
  [COACH]: "Do you want to de-activate this Coach",
};

export const ON_ADD_NAVIGATE_ROUTE: OnAddNavigateRouteInterface = {
  [PLAYER]: "/players/new",
  [COACH]: "/coaches/new",
  [BATCH]: "/batches/new",
};

export const ON_EDIT_NAVIGATE_ROUTE: OnEditNavigateRoute = {
  [PLAYER]: (id: number) => `/players/${id}/edit`,
  [COACH]: (id: number) => `/coaches/${id}/edit`,
  [BATCH]: (id: number) => `/batches/${id}/edit`,
};

export const ON_ROWCLICK: OnRowClickInterface = {
  [PLAYER]: (id: number) => `/players/${id}`,
  [COACH]: (id: number) => `/coaches/${id}`,
  [BATCH]: (id: number) => `/batches/${id}`,
};

export const ACTIVE = "active";
export const BATCH_TITLE = BATCH;

export const HEADER_TITLE: HeaderTitleInterface = {
  [PLAYER]: "Players",
  [COACH]: "Coaches",
  [BATCH_TITLE]: "Batches",
};

export const BUTTONT_TEXT: ButtonTextInterafce = {
  [PLAYER]: "Create New Player",
  [COACH]: "Create New Coach",
  [BATCH_TITLE]: "Create New Batch",
};

export const getColumns: GetColumnsInterface = {
  [PLAYER]: ({ onEdit, onDelete, permissions }: ColumnPropsType) =>
    getPlayerColumns({
      onEditPlayers: onEdit,
      onDeletePlayer: onDelete,
      permissions: permissions,
    }),
  [COACH]: ({ onEdit, onDelete, permissions }: ColumnPropsType) =>
    getCoachColumns({
      onEditCoaches: onEdit,
      onDeleteCoach: onDelete,
      permissions: permissions,
    }),
  [BATCH]: ({
    onEdit,
    permissions,
    onApplyFilter,
    onCancelBatch,
    filterConfig,
  }: ColumnPropsType) =>
    getBatchColumns({
      onEditBatch: onEdit,
      permissions: permissions,
      onApplyFilter: onApplyFilter,
      onCancelBatch: onCancelBatch,
      filterConfig: filterConfig,
    }),
  [MATCH_PLAYED]: () => getMatchPlayedColumns(),
};

export const COMMON_LIST_API_RESPONSE: CommonListApiResponseInterface = {
  total_count: 0,
  data: [],
};

export const FILTERED_LIST: FilteredListInterface = {
  [PLAYER]: "players",
  [COACH]: "coaches",
  [BATCH]: "batches",
  [MATCH_PLAYED]: "matches",
};

export const TOTAL_MATCH_PLAYED = "Total Matches Played:";

export const getListURL = ({
  academyId,
  searchInput,
  selectValue,
  currentPage,
  active,
  sort_column,
  order_by,
  matchPlayedUserType,
  id,
}: GetListURLParamProps): GetListURLProps => ({
  [BATCH]: `${ACADEMY_BASE_URL}${getAcademyId(
    academyId
  )}/batches?page=${currentPage}&search=${searchInput}&sport_id=${selectValue}&type=${active}&sort_column=${sort_column}&order_by=${order_by}`,
  [PLAYER]: `${ACADEMY_BASE_URL}${getAcademyId(
    academyId
  )}/players?page=${currentPage}&search=${searchInput}&sport_id=${selectValue}&order_by=name&order_type=asc`,
  [COACH]: `${ACADEMY_BASE_URL}${getAcademyId(
    academyId
  )}/coaches?page=${currentPage}&search=${searchInput}&sport_id=${selectValue}&order_by=name&order_type=asc`,
  [MATCH_PLAYED]: `${ACADEMY_BASE_URL}${getAcademyId(academyId)}/${
    matchPlayedUserType && USER_LIST[matchPlayedUserType]
  }/${id}/matches?page=${currentPage}&search=${searchInput}&master_sport_id=${selectValue}`,
});

export const BULK_UPLOAD_PLAYER_TITLE = "Players";
