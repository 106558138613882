import { ButtonIcon } from "Components/Shared";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faTrash } from "@fortawesome/free-solid-svg-icons";
import { EMPTY_LABEL } from "App/constants";
import { formatDate, formatTimestamp } from "Utils/helper";
import TextWrapper from "Components/Shared/TextWrapper";
import { PromotionalVideoDataType } from "./types";

export const getVideoColumns = (onDeleteRowClicked: Function) => [
  {
    dataField: "video",
    title: "Video",
    colWidth: "20%",
    formatter: (data: PromotionalVideoDataType) => {
      const { thumbnail_url } = data;

      return (
        <div className="thumbnail-box position-relative">
          <img src={thumbnail_url} alt={`video thumbnail ${data.title}`} />
          <FontAwesomeIcon
            icon={faPlay}
            color="white"
            className="position-absolute fs-5 icon-media"
          />
        </div>
      );
    },
  },
  {
    dataField: "title",
    title: "Name",
    colWidth: "30%",
  },
  {
    dataField: "description",
    title: "Description",
    colWidth: "30%",
    formatter: (data: PromotionalVideoDataType) => {
      return data.description ? data.description : EMPTY_LABEL;
    },
  },
  {
    dataField: "uploaded_by",
    title: "Uploaded by",
    colWidth: "10%",
  },
  {
    dataField: "created_at",
    title: "Created at",
    colWidth: "10%",
    formatter: (data: PromotionalVideoDataType) => {
      return data.created_at ? (
        <TextWrapper width={120} tooltipText={formatDate(data.created_at)}>
          {formatTimestamp(data.created_at)}
        </TextWrapper>
      ) : (
        EMPTY_LABEL
      );
    },
  },

  {
    dataField: "delete",
    title: "Action",
    colWidth: "10%",
    formatter: (data: PromotionalVideoDataType) => {
      return (
        <div className="d-flex">
          <ButtonIcon
            onClick={(event) => {
              event.stopPropagation();
              onDeleteRowClicked(data.id);
            }}
          >
            <FontAwesomeIcon icon={faTrash} />
          </ButtonIcon>
        </div>
      );
    },
  },
];
