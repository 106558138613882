import axios from "axios";
import { GetListParam } from "Interfaces/Components/Shared/DisplayListComponentInterface";
import { getListURL } from "Components/Shared/DisplayListComponent/constants";

export const getList = async ({
  userType,
  academyId,
  searchInput,
  selectValue,
  currentPage,
  active,
  sort_column,
  order_by,
  matchPlayedUserType,
  id,
}: GetListParam) => {
  const URL = getListURL({
    academyId,
    searchInput,
    selectValue,
    currentPage,
    active,
    sort_column,
    order_by,
    matchPlayedUserType,
    id,
  })[userType];

  const { data } = await axios.get(URL);
  if (data) {
    return data;
  }
  return [];
};
