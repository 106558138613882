import { ButtonIcon } from "Components/Shared";
import { IPlayerVideoData } from "./types";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faTrash } from "@fortawesome/free-solid-svg-icons";
import { formatDate } from "Utils/helper";
import { EMPTY_LABEL } from "App/constants";

export const getVideoColumns = (onDeleteRowClicked: Function) => [
  {
    dataField: "video",
    title: "Asset",
    colWidth: "10%",
    formatter: (data: IPlayerVideoData) => {
      const { thumbnail_url, title } = data;

      return (
        <div className="thumbnail-box position-relative">
          <img src={thumbnail_url} alt={`video thumbnail ${title}`} />
          <FontAwesomeIcon
            icon={faPlay}
            color="white"
            className="position-absolute fs-5 icon-media"
          />
        </div>
      );
    },
  },
  {
    dataField: "title",
    title: "Title",
    colWidth: "10%",
    formatter: (data: IPlayerVideoData) => {
      return data.title ? data.title : EMPTY_LABEL;
    },
  },

  {
    dataField: "description",
    title: "Description",
    colWidth: "30%",
    formatter: (data: IPlayerVideoData) => {
      return data.description ? data.description : EMPTY_LABEL;
    },
  },
  {
    dataField: "comments",
    title: "Comments",
    colWidth: "30%",
    formatter: (data: IPlayerVideoData) => {
      const { comments } = data;

      if (comments.length === 0) {
        return EMPTY_LABEL;
      }

      return (
        <ul>
          {comments.map((comment) => (
            <li key={comment.id}>
              <b>{comment.commented_by}</b> : <span>{comment.text}</span> -{" "}
              <span className="text-muted">
                {formatDate(comment.created_at)}
              </span>
            </li>
          ))}
        </ul>
      );
    },
  },
  {
    dataField: "delete",
    title: "Action",
    colWidth: "10%",
    formatter: (data: IPlayerVideoData) => {
      return (
        <div className="d-flex">
          <ButtonIcon
            onClick={(event) => {
              event.stopPropagation();
              onDeleteRowClicked(data.id);
            }}
          >
            <FontAwesomeIcon icon={faTrash} />
          </ButtonIcon>
        </div>
      );
    },
  },
];
